import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { loginDoctor } from '../../services/login'
import { useNavigation } from './NavigationContext'
import { Forms } from '../../forms/Forms'

export const Login: React.FC = () => {
  const { triggerRedirect } = useNavigation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    loginDoctor(username, password).then((response) => {
      localStorage.setItem('jwt', response.token)
    localStorage.setItem('doctor', JSON.stringify(response.doctor))
      triggerRedirect('/appointments')
    })
      .catch((error) => {
        let e = error.response.data.error as string
        setErrors([e])
      })
  }



  useEffect(() => {
    if (localStorage.getItem('jwt')) {
      let doctor = JSON.parse(localStorage.getItem('doctor') as string)
      triggerRedirect('/appointments')
    }
  }, [])

  return (
    <Container>

      <div style={{ height: '100vh' }} className='d-flex align-items-center justify-content-center'>
        <Row className='w-100'>
          <Col sm={{ offset: 4, span: 4 }}>
            <Card className='rounded-0 border border-primary'>
              <div className="px-4">

              </div>
              <Card.Header className='w-100'>Denticare</Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <FormControl value={username} onChange={({ target }) => setUsername(target.value)} size='sm' type='text' placeholder='Usuario' className='rounded-0' />
                  <FormControl value={password} onChange={({ target }) => setPassword(target.value)} size='sm' type='password' placeholder='Contraseña' className='rounded-0 mt-2' />
                  <Button type='submit' size='sm' variant='primary' className='rounded-0 mt-2 mb-3 w-100'>Iniciar sesión</Button>
                  {errors.length > 0 && <Forms.Errors errors={errors} />}
                </Form>
              </Card.Body>


            </Card></Col>
        </Row>

      </div>

    </Container>
  )
}